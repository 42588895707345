import React from "react";
import { Dropdown, Form, Modal, ModalBody, Spinner } from "react-bootstrap";
import { withRouter } from "../../customNavigate/withRouter";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getUserProfile } from "../../../api/user";
import { uploadAttachmentOfChat } from "../../../api/chat";
import Bubble from "../../elements/messages/Bubble";
import "../Inbox.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LocciAvatar } from "../../commons/LocciAvatar";
import { composeInitialProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import Picker from "emoji-picker-react";
import IconButton from "../../elements/buttons/IconButton";
import BackButton from "../../commons/BackButton";
import ChatUserModal from "./ChatUsersModal";
import { MessageProvider } from "lib/context/message-context/messageContext";
import AttachmentModal from "./AttachmentModal";
import AudioRecording from "./AudioRecording";
import { SOCKET_URL } from "../../../global";
import Lightbox from "../../elements/lightbox";
import PhotoClicker from "./PhotoClicker";
import Button from "../../elements/buttons/Button";
import Loader from "../../../components/posts/single-post/PostLoader/postLoader.js";
import { getAttchamentIcon, ngxBase64 } from "lib/utils/Commons";
import { useDispatch } from "react-redux";

import VideoCapture from "./VideoCapture";
import { downloadChatFiles, getGroupAdmins } from "../../../api/common";
import { withScrollHookHOC } from "./CustomScoller";

import InfiniteScroll from "react-infinite-scroll-component";
import UserDetails from "../../user-details/index";
import IconLink from "../../elements/buttons/IconLink";
import moment from "moment";
import AllPaticipantsModal from "../groupchat/allParticipants";
import AddParticipantsModal from "../groupchat/addParticipants";
import ToastModal from "../../commons/ToastModal";
import GlobalConfirmDialogue from "../../commons/GlobalConfirmDialogue";
import ForwardGroupChatModal from "./ForwardGroupChatModal";
import './privateChatInfo.scss';
import { useSocketContext } from "lib/context/socketContext.js";
import { ConstructionOutlined } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import {  generateVideoThumbnails } from "lib/utils/thumbnails";
import { useTranslation } from "react-i18next";
import ActionBar from "components/actionBar";

let clrArr = [
  "#660033",
  "#666600",
  "#CC6600",
  "#CCCC00",
  "#996633",
  "#990066",
  "#666699",
  "#666666",
  "#66CCFF",
  "#FF0000",
  "#FF9900",
  "#000033",
  "#666699",
  "#FFCC99",
  "#6600FF",
  "#000033",
  "#FFFF33",
  "#660033",
  "#666600",
  "#CC6600",
  "#CCCC00",
  "#996633",
  "#990066",
  "#666666",
  "#66CCFF",
  "#FF0000",
  "#FF9900",
  "#000033",
  "#FFCC99",
  "#6600FF",
  "#000033",
  "#FFFF33",
  "#660033",
  "#666600",
  "#CC6600",
  "#CCCC00",
  "#996633",
  "#990066",
  "#666699",
  "#666666",
  "#66CCFF",
  "#FF0000",
  "#FF9900",
  "#000033",
  "#666699",
  "#FFCC99",
  "#6600FF",
  "#000033",
  "#FFFF33",
  "#660033",
  "#666600",
  "#CC6600",
  "#CCCC00",
  "#996633",
  "#990066",
  "#666666",
  "#66CCFF",
  "#FF0000",
  "#FF9900",
  "#000033",
  "#FFCC99",
  "#6600FF",
  "#000033",
  "#FFFF33",
];

//infinity scroll
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

const initalChatStates = {
  userId: null,
  messages: [],
  // filteredMessages: [],
  message: "",
  offset: 0,
  loading: true,
  allowGet: true,
  userData: null,
  isStickerVisible: false,
  query: "",
  selectedMsg: null,
  showUserList: false,
  messageContext: null,
  showAttachmentModal: false,
  allParticipants: false,
  addParticipants: false,
  deleteLoader: false,
  addLoader: false,
  sortedParticipants: [],
  recordAudioShow: false,
  toastMessage: "",
  showToast: false,
  roomID: 0,
  replyId: null,
  isLightboxOpen: false,
  lightboxMedia: [],
  lightboxIndex: 0,
  intervalId: null,
  repliedData: null,
  currentUserDetails: null,
  showDeleteModal: false,
  multipleAttchamentId: null,
  mediaData: null,
  parentMsgThumb: null,
  attchamentType: null,
  isSending: false,
  videoCapture: false,
  videoBlob: null,
  errorMessage: "",
  showErrorModal: false,
  startNumberOfMsg: 0,
  endNumberOfMsg: 10,
  isLoadMoreMsg: false,
  tempData: {},
  hasMore: true,
  chatInnerHeight: false,
  goToTheMessage: false,
  os: "",
  browser: "",
  isUserBlocked: false,
  showUserDetails: false,
  currentPage: 1,
  numberOfRecords: 20,
  isRenderedFirstTime: false,
  isSearching: false,
  clipboardImg: null,
  roomInfo: {},
  allUsers: [],
  allUsersSecond: [],
  exitGroup: false,
  userIdForModal: false,
  selectedMsgName: "",
  checkReplyMessage: false,
  replyMessageId: "",
  onReplyLoad: false,
  totalPages: 0,
  participantDatas: [],
  isTabActive: true,
  messagesToRead: [],
  documentTitle: "",
  // showPopup: false,
  // file: null
  file: null,
  videoThumbnail: null,
  showErrorModal: false,
  errorMsg: "",
  showMixMediaUploader: false,
  toggleSearch: false,
  handleCompression:true,
  isBrowserSafari:false,
  showCommonUploader:false,
};


// const mediafileTypes = ["PNG", "JPG", "JPEG", "BMP", "GIF", "WEBP", "MP4"];
// const docFileType = ["XLSX", "XLS", "DOC", "DOCX", "XML", "PDF"];


const mediafileTypes = [
  "JPG", "JPEG", "PNG", "GIF", "BMP", "TIFF", "TIF", "SVG", "PSD", "AI", "EPS",   // Images
  "MP4", "AVI", "MOV", "FLV", "MKV", "WMV", "MPEG", "MPG", "3GP", "WEBM",         // Video
];

const docFileType = [
  "DOC", "DOCX", "PDF", "XLS", "XLSX", "PPT", "PPTX", "TXT", "RTF", "CSV", "ODT", // Documents
  "ODS", "ODP", "EPUB", "MOBI", "AZW", "TEX", "MARKDOWN", "MD", "KEY", "PAGES",    // Additional Documents
  "NUMBERS", "JSON", "LOG", "ZIP", "RAR", "7Z", "TAR", "GZ", "BZ2", "ISO", "DMG", "CAB", // Compressed
  "LZ", "XZ", "Z", "TAR.GZ", "TAR.BZ2", "TAR.XZ", "TGZ", "TBZ2", "TLZ", "TXZ",  // Additional Documents
  "M4A", "M4V", "AIFF", "MID", "MIDI", "RA", "RAM", "RM",                         // Additional Media
  "OPUS", "AIF", "ASF", "VOB", "M2TS", "MTS", "F4V", "DV", "DIVX", "XVID", "MP3"   // More Audio and Video
];

const allFileTypes = [
  "JPG", "JPEG", "PNG", "GIF", "BMP", "TIFF", "TIF", "SVG", "PSD", "AI", "EPS",   // Images
  "MP4", "AVI", "MOV", "FLV", "MKV", "WMV", "MPEG", "MPG", "3GP", "WEBM",         // Video
  "DOC", "DOCX", "PDF", "XLS", "XLSX", "PPT", "PPTX", "TXT", "RTF", "CSV", "ODT", // Documents
  "ODS", "ODP", "EPUB", "MOBI", "AZW", "TEX", "MARKDOWN", "MD", "KEY", "PAGES",    // Additional Documents
  "NUMBERS", "JSON", "LOG", "ZIP", "RAR", "7Z", "TAR", "GZ", "BZ2", "ISO", "DMG", "CAB", // Compressed
  "LZ", "XZ", "Z", "TAR.GZ", "TAR.BZ2", "TAR.XZ", "TGZ", "TBZ2", "TLZ", "TXZ",  // Additional Documents
  "M4A", "M4V", "AIFF", "MID", "MIDI", "RA", "RAM", "RM",                         // Additional Media
  "OPUS", "AIF", "ASF", "VOB", "M2TS", "MTS", "F4V", "DV", "DIVX", "XVID", "MP3"   // More Audio and Video
]


class CustomMessage extends React.Component {
  render() {
    const {heading, message} = this.props;

    return (
      <div className="custom-message">
        <p className="heading">{heading}</p>
        <p className="para">{message}</p>
      </div>
    );
  }
}

class ChatComponent extends React.Component {
  socket = {};
  state = initalChatStates;
  fileUploaderRender = React.createRef(false);
  currentFocusEl = React.createRef(null)
  constructor(props) {
    super(props);
    this.psRef = React.createRef();
    this.startMsgRef = React.createRef();
    this.endMsgRef = React.createRef();
    this.startMsgRef.current = 0;
    this.endMsgRef.current = 10;
    this.chatWindowRef = React.createRef();
    this.currentPage = 1;
    this.textareaRef = React.createRef();
    this.firstTimeRender = React.createRef(false);
  }

  getSockets() {
    const _this = this;

    // _this.socket.server = _this.props.socketContext.webScoketRef.current;
    
    if (
      _this.socket &&
      _this.socket.server !== null
    ) {
      // console.log(
      //   "sed reusing the socket connection [state = " +
      //   _this.socket.server.readyState +
      //   "]: " +
      //   _this.socket.server.url
      // );

      return new Promise(function (resolve, reject) {
        _this.socket.server.onmessage = (e) => {
          _this.onMessageListener(e);
        }

        resolve(_this.socket.server);
        
        _this.socket.server.onerror = function (err) {
          console.error("socket connection error : ", err);
          reject(err);
        };
      })
    } else {
      return new Promise(function (resolve, reject) {
        
        _this.props.socketContext.initializeWebSocket();
        // _this.socket.server = _this.props.socketContext.webScoketRef.current

        if (_this.socket.server?.readyState !== WebSocket.OPEN) {
          _this.socket.server.onopen = function () {
            console.log(
              " sed socket connection is opened [state = " +
              _this.socket.server.readyState +
              "]: " +
              _this.socket.server.url
            );
            resolve(_this.socket.server);
          };
        }

        _this.socket.server.onmessage = (e) => {
          _this.onMessageListener(e);
        }

        _this.socket.server.onerror = function (err) {
          console.error("socket connection error : ", err);
          reject(err);
        };
      });
    }
  }

  globalGetMsgList() {

    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          // console.log("called update..........................");

          let userData = JSON.parse(localStorage.getItem("profile"));
          let param2 = {
            event: "chat_user_list",
            from_user_id: this.props.match.params.id,
            current_user: userData.userId,
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,

            page_no: this.state.currentPage,
            // offset: this.state.numberOfRecords,
            record_per_page: this.state.numberOfRecords,


          };
          server.send(JSON.stringify(param2));
        }
      })
      .catch((err) => {
        console.log("error on getSocktes", err);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.firstTimeRender.current == true && !this.state.isSearching && this.props.query === "") {
      if (this.textareaRef.current) {
        const length = this.textareaRef.current.value.length;
        this.textareaRef.current.setSelectionRange(length, length);
        this.textareaRef.current.focus();
      }
    }

    if (this.socket.server?.readyState != this.props.socketContext.webScoketRef.current?.readyState) {
      if (this.props.socketContext.webScoketRef.current?.readyState === WebSocket.OPEN) {
        this.socket.server = this.props.socketContext.webScoketRef.current;
        this.socketInit();
      }
    }
    
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(initalChatStates);
      this.resetChat();
      // window.location.reload();
    }

    if (this.state.query !== this.props.query) {
      this.getSockets()
        .then((server) => {
          if (server.readyState) {
            let userData = JSON.parse(localStorage.getItem("profile"));
            let param2 = {}
            if (this.props.query !== "") {
              param2 = {
                event: "chat_user_list",
                from_user_id: this.props.match.params.id,
                current_user: userData.userId,
                LC_room_id:
                  this.props.chattype === "private"
                    ? this.state.roomID
                    : this.props.match.params.id,
                search: this.props.query,
              };
            } else {
              param2 = {
                event: "chat_user_list",
                from_user_id: this.props.match.params.id,
                current_user: userData.userId,
                LC_room_id:
                  this.props.chattype === "private"
                    ? this.state.roomID
                    : this.props.match.params.id,
                search: this.props.query,
                page_no: 1,
                // offset: this.state.numberOfRecords,
                record_per_page: this.state.numberOfRecords,

              };
            }
            server.send(JSON.stringify(param2));
          }
        })
        .catch((err) => {
          console.log("error on getSocktes", err);
        });
    }

    if (this.chatWindowRef.current && !this.state.chatInnerHeight) {
      //subtract height of header, input box and padding from bottom
      let height = 101 + 86;
      if (window.innerWidth < 767) {
        //bottom padding 0px
        height += 0;
      } else if (window.innerWidth < 991) {
        //bottom padding 91px
        height += 91;
      } else {
        //bottom padding 60px
        height += 60;
      }
      this.setState({
        chatInnerHeight: this.chatWindowRef.current.clientHeight - height,
      });
    }

    if (this.state.goToTheMessage) {
      this.showParentMsg(this.state.goToTheMessage);
      this.setState({
        goToTheMessage: false,
        hasMore: true,
      });
    }

  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      this.setState({ browser: "Chrome" });
    }
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    this.setState({ os: os });
  }

  componentDidMount() {
    let _this = this;
    this.props.socketContext.initializeWebSocket();
    _this.socket.server = this.props.socketContext.webScoketRef.current;
    this.setDraftMessage();

    document.addEventListener("paste", function (evt) {
      let allFiles = [];
      const clipboardItems = evt.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter(function (item) {
        // Filter the image items only
        return /^image\//.test(item.type);
      });
      if (items.length === 0) {
        return;
      }
      _this.setState({
        isSending: true,
      });

      for (let item of items.slice(0, 10)) {
        const blob = item.getAsFile();
        const fileName = blob?.name;

        const preview = URL.createObjectURL(blob);
        let file = new File(
          [blob],
          fileName,
          { type: "image/jpeg", lastModified: new Date().getTime() },
          "utf-8"
        );
        let container = new DataTransfer();
        container.items.add(file);
        // let files=container.files;
        let fileObj = {
          preview: preview,
          file: file,
          type: "p",
        };
        allFiles.push(fileObj);
      }
      console.log("third", allFiles);
      _this.setState({
        clipboardImg: allFiles,
        showAttachmentModal: true,
      });

      setTimeout(() => {
        _this.setState({ isSending: false });
      }, 2000);
    });

    this.setState({
      documentTitle: document.title
    });

    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    window.addEventListener("focus", this.handleVisibilityChange);
    window.addEventListener("blur", this.handleVisibilityChange);

    this.resetChat();

    const userAgent = navigator.userAgent;
    const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    this.setState({
      isBrowserSafari: isSafari
    });
  }
    

  handleVisibilityChange = () => {
    const isActive = document.visibilityState === "visible" && document.hasFocus();
    if (isActive) {
      this.setState({ isTabActive: true });

      document.title = this.state.documentTitle;

      this.state.messagesToRead.forEach((loci_messID) => {
        this.markMsgAsRead(loci_messID);
      })
    } else {
      this.setState({ isTabActive: false });
    }
  }

  setDraftMessage = () => {
    this.firstTimeRender.current = true

    let result = this.props?.draftMessage?.find((e) => {
      return e.chatId === this.props.params.id;
    });

    // Extract the message if a matching element is found
    if (result) {
      this.setState({ message: result.message }, () => {
        this.removeDraftMessage();
      });
    }
  }

  removeDraftMessage = () => {
    const result = this.props.draftMessage?.filter(e => e.chatId !== this.state.roomID);
    this.props.setMyDraftMessage(result || []);
  }

  resetChat = async () => {
    // this.setState({ messageContext: this.context });
    let userData = JSON.parse(localStorage.getItem("profile"));
    if (this.props.chattype === "group")
      this.getInboxList();
    if (this.props.chattype === "group") {
      this.setState({ userId: userData.userId });
    } else {
      this.setState({ userId: this.props.match.params.id });
    }
    this.setState({ currentUserDetails: userData });
    this.getOS();

    await this.getUserCallBack()
    this.socketInit();

    // if (!this.state.intervalId) {
    //   clearInterval(this.state.intervalId);
    //   this.setState({
    //     intervalId: setInterval(() => {
    //       this.getSockets();
    //     }, 10000),
    //   });
    // }
  };

  getUserCallBack = () => {
    if (this.props.chattype === "private") {
      this.getUser()
    }
  }

  getInboxList = () => {
    getGroupAdmins(this.props.token, this.props.query).then((res) => {
      if (res.data.row) {
        const data = res.data.row;
        let modifiedData = [];
        let userDetails = JSON.parse(localStorage.getItem("profile"));
        if (userDetails) {
          modifiedData = data.filter(
            (me) => me.FK_userID !== userDetails.userId
          );
        }

        this.setState({ allUsersSecond: [...modifiedData] });
      }
    });
  };

  socketInit() {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let param = {
            event: "get_chat_room",
            LC_to_user: this.props.match.params.id,
          };
          server.send(JSON.stringify(param));
        }
      })
      .catch((err) => {
        // error here
        console.log("error on getSocktes", err);
      });
  }

  onMessageListener(event) {
    const data = JSON.parse(event.data);

    if (data.event === "chat_user_list") {
      if (data?.status === "ERR") this.props.history.push("/inbox");

      this.setState((prevState) => ({
        messages: [...prevState?.messages, ...data?.list],
        isLoadMoreMsg: true,
        isRenderedFirstTime: true,
        totalPages: data?.total_pages
      }));

      if (this.state.checkReplyMessage) {
        this.scrollToParentMsg(this.state.replyMessageId)
      }

      this.setState({ isLoadMoreMsg: false });

      if (this.state.messages?.length <= 10) {
        this.setState({
          hasMore: false,
        });
      }
    }

    if (
      data.event === "chat_user_list" &&
      this.state.isRenderedFirstTime &&
      this.props.query !== ""
    ) {
      // console.log("called chat_user_list 2",data.list);

      this.setState({
        messages: data.list,
        isLoadMoreMsg: true,
        query: this.props.query,
        isSearching: true,
      });
      // console.log("firstmsg", data.list, this.state.messages);

      this.scrollHandle();
      this.setState({ isLoadMoreMsg: false });
      if (this.state.messages?.length <= 10) {
        this.setState({
          hasMore: false,
        });
      }
    }

    if (
      data.event === "chat_user_list" &&
      this.state.isRenderedFirstTime &&
      this.state.isSearching &&
      this.props.query === ""
    ) {
      this.setState({
        messages: data.list,
        isLoadMoreMsg: true,
        query: this.props.query,
        endNumberOfMsg: 10,
        currentPage: 1,
      });
      // console.log("firstmsg", data.list, this.state.messages);

      this.scrollHandle();
      // console.log('this.state.messages?.length',this.state.messages?.length);
      this.setState({ isLoadMoreMsg: false, hasMore: true });
      if (this.state.messages?.length <= 10) {
        this.setState({
          hasMore: false,
        });
      }
    }
    if (
      data.list?.length === this.state.numberOfRecords &&
      this.state.isRenderedFirstTime
    ) {
      this.setState({ isRenderedFirstTime: false });
    } else if (data?.event === "get_chat_header") {
      this.setState({ roomID: data.roomID });

      this.setState({
        roomInfo: data,
        loading: false,
      });
      this.assignColors(data?.participants);
      this.sortParticipants(data?.participants);
      this.filterParticipants(data?.participants);
      this.setState({
        participantDatas: data?.participants
      })
    } else if (
      data.event === "update_room_info" &&
      data.action === "leave_group"
    ) {
      this.setState({
        exitGroup: false,
        groupLeft: true,
      });
      setTimeout(this.redirectToChat, 2000);
    } else if (
      data.event === "update_room_info" &&
      data.action === "remove_user"
    ) {
      this.setState({
        deleteLoader: false,
        // allParticipants: false,
        toastMessage: data.message,
        showToast: true,
      });
      setTimeout(this.hideToast, 2000);
      this.getChatHeader();
    } else if (
      data.event === "update_room_info" &&
      data.action === "add_participants"
    ) {
      this.setState({
        addLoader: false,
        addParticipants: false,
        toastMessage: data.message,
        showToast: true,
      });
      setTimeout(this.hideToast, 2000);
      this.getChatHeader();
    } else if (data.event === "get_chat_room") {
      this.setState({ roomID: data.roomID });

      this.getSockets()
        .then((server) => {
          if (server.readyState) {
            let userData = JSON.parse(localStorage.getItem("profile"));
            let param2 = {
              event: "chat_user_list",
              from_user_id: this.props.match.params.id,
              current_user: userData.userId,
              LC_room_id:
                this.props.chattype === "private"
                  ? data.roomID
                  : this.props.match.params.id,

              page_no: this.state.currentPage,
              record_per_page: this.state.numberOfRecords,
            };
            server.send(JSON.stringify(param2));

            if (this.props?.forwardMessage) {
              this.setState({
                isRenderedFirstTime: false,
              });

              let param = { ...this.props?.forwardMessage }
              param["LC_forward_id"] = this.props?.forwardMessage?.loci_messID;
              param["LC_room_id"] =
                this.props.chattype === "private"
                  ? data.roomID
                  : this.props.match.params.id;
              param["event"] = "send_chat";
              server.send(JSON.stringify(param));

              this.setState({
                messages: [],
                endNumberOfMsg: 10,
              });
              this.props?.setForwardMessage(null);
              this.getUser();
            }
            this.setState({ loading: false });

            this.getChatHeader();
          }
          if (data?.ER) {
            this.setState({
              isUserBlocked: true,
              loading: false,
            });
            return false;
          }
        })
        .catch((err) => {
          console.error("error on getSocktes", err);
        });
    } else if (data.event === "send_chat") {
      if (data.LCM_room_id !== this.getLcRoomId()) return;
      
      if (this.state.currentUserDetails?.userId != data.LCM_from) {
        if (this.state.isTabActive) {
          this.markMsgAsRead(data.loci_messID);
        } else {
          this.setState({
            messagesToRead: [
              ...this.state.messagesToRead,
              data.loci_messID
            ]
          })

          const { t } = this.props;

          document.title = this.state.documentTitle + ' - (' + this.state.messagesToRead.length + ') ' + t("chat_new_message");
        }
      }
      if (
        data.loci_messID !== "" &&
        this.state.mediaData !== null &&
        this.state.mediaData?.length > 0
      ) {
        this.attachmentUsingFormData(data.loci_messID);
      }

      if (data.LC_message_type === "system") {
        this.setState({ messages: [data, ...this.state.messages] });
      }

      data.FK_userID = data.LCM_from;
      if (this.state.mediaData?.length < 1 || this.state.mediaData === null)
        if (data.LC_message_type === "text") {
          this.setState((prevState) => {
            const preState = prevState.messages.filter(
              (m) => m.loading === undefined
            );
            return {
              messages: [data, ...preState],
              isSending: false,
            };
          });

          setTimeout(() => {
            this.scrollHandle();
          }, 50);
        }

      if (data.LC_forward_id !== "0" && data.LC_message_type === "attachment") {
        // this.attachmentUsingFormData(data.loci_messID);
        this.setState((prevState) => {
          let preState2 = prevState.messages.filter(
            (m) => m.loci_messID !== data.loci_messID
          );
          return {
            messages: [data, ...preState2],
            isSending: false,
          };
        });
      }
    }
    if (
      data.LC_message_type === "attachment" &&
      data.LCM_attachment?.length < 1
    ) {
      return;
    } else if (data.event === "delete_single_chat") {
      // console.log("delete_single_chat", data);
      let messages = this.state.messages.filter(
        (msg) => msg.loci_messID != data.deleted_id
      );
      // this.deleteMsgConfirm()
      this.setState({ messages: messages });
    } else if (data.event === "upload_attachment") {
      data.FK_userID = data.LCM_from;
      if (data.LCM_room_id !== this.getLcRoomId()) return;
      if (this.state.currentUserDetails.userId != data.LCM_from) {
        this.markMsgAsRead(data.loci_messID);
      }
      this.setState((prevState) => {
        let preState = prevState.messages.filter(
          (m) => m.loading === undefined
        );

        let preState2 = prevState.messages.filter(
          (m) => m.loci_messID !== data.loci_messID
        );
        return {
          messages: [data, ...preState2],
          isSending: false,
        };
      });
      setTimeout(() => {
        this.scrollHandle();
      }, 50);
    } else if (data.event === "delete_all_chat") {
      this.props.history.go(-1);
    }
  }

  getLcRoomId = () => {

    if (this.props.chattype === "private") {
      return this.state.roomID;
    } else {
      return this.props.match.params.id;
    }
  };

  filterParticipants = (data) => {
    let arr = [];
    data?.map((dt) => {
      arr.push(dt?.userID);
    });

    let modiArr = [];

    const mapUsers = () => {
      this.state.allUsersSecond?.map((dt) => {
        if (!arr.includes(dt?.FK_userID)) {
          modiArr.push(dt);
        }
      });
    }

    if (this.state.allUsersSecond.length > 0) {
      mapUsers();
    } else {
      let interval = setInterval(() => {
        if (this.state.allUsersSecond.length > 0) {
          clearInterval(interval);
          mapUsers();
        }
      }, 500);
    }
    this.setState({ allUsers: modiArr });
  };

  assignColors = (data) => {
    let obj = {};

    for (var i = 0; i < data.length; i++) {
      obj[data[i].userID] = clrArr[i];
    }

    this.setState({ tempData: obj });
  };

  hideToast = () => {
    this.setState({ showToast: false });
  };

  redirectToChat = () => {
    this.props.history.push("/inbox");
  };

  forwordMessage = (id, navigateTo) => {
    // console.log("forward user clicked", id);
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let param = {
            event: "get_chat_room",
            LC_to_user: id,
          };
          server.send(JSON.stringify(param));
          this.setState({
            showUserList: false,
          });
          this.props.history.push(navigateTo + id);
        }
      })
      .catch((err) => {
        // error here
        console.error("error on getSocktes", err);
      });
  };

  scrollHandle() {
    if (this.psRef && this.psRef.current) {
      this.psRef.current.scrollTo(0, 100000);
    }
  }

  componentWillUnmount() {
    if (this.state.message !== ""){
      this.handleDraftMessages()
    }
    this.setState({roomID: 0});

    if (this.socket.server && this.socket.server.readyState < 2) {
      this.socket.server.onmessage = null;
    }

    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    window.removeEventListener("focus", this.handleVisibilityChange);
    window.removeEventListener("blur", this.handleVisibilityChange);

    clearInterval(this.state.intervalId);
  }

  onEmojiClick = () => {
    this.setState((prevState) => ({
      isStickerVisible: !prevState.isStickerVisible,
    }));
  };

  onEmojiSelect = (emoji, event) => {
    this.setState((prevState) => ({
      message: prevState.message.concat(emoji.emoji),
    }));
  };
  async;

  getUser() {
    getUserProfile(this.props.token, this.props.match.params.id).then((res) => {
      if (res.data.OK) {
        var data = res.data.OK;
        let userData = {
          title: data.USE_firstname + " " + data.USE_surename,
          userPicture: data.LCU_fbpic ? data.LCU_fbpic : data.USE_pic,
        };
        this.setState({ userData: userData });

        return true;
      }
    });
  }

  markMsgAsRead = (id) => {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let readParams = {
            event: "mark_as_read_by_id",
            LC_chat_id: id,
          };
          server.send(JSON.stringify(readParams));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  getChatHeader = () => {
    this.setState({
      loading: true,
    });

    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let params = {
            event: "get_chat_header",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
            user_id: this.state.userId,
          };
          server.send(JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  getRoomInfo = () => {
    this.setState({
      loading: true,
    });
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let roomId = this.props?.match?.params?.id;

          let params = {
            event: "room_info",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
            user_id: this.state.userId,
            admin: true,
          };
          server.send(JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  deleteAllMessage = () => {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let param2 = {
            event: "delete_all_chat",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
          };
          server.send(JSON.stringify(param2));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  deleteMessage = (index, type) => {
    var messages = this.state.messages;
    var messageId = messages[index].loci_messID;
    var self = this;
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let param2 = {
            event: "delete_single_chat",
            loci_messID: messageId,
            everyone: type === "all" ? true : false
          };
          server.send(JSON.stringify(param2));
        }
      })
      .catch((err) => {
        console.log("error on getSocktes", err);
      });
  };

  setSelectedMsg = (data) => {
    this.setState({
      selectedMsgName: data?.USE_firstname + " " + data?.USE_surename,
      selectedMsg: data.LCM_text,
      replyId: data.loci_messID,
      repliedData: data,
      attchamentType:
        data.LCM_attachment.length < 1 ? null : data.LCM_attachment[0]?.atype,
    });

    document.getElementById("chatinput").focus();
  };

  handleUserListOpen = (data) => {
    const message = data;

    this.props?.setForwardMessage(message);

    this.setState({
      messageContext: message,
      showUserList: true,
    });
  };

  handleUserListClose = () => {
    this.props?.setForwardMessage(null);
    this.setState({
      showUserList: false,
    });
  };

  handleDraftMessages = () => {
    this.props?.setMyDraftMessage([...this.props.draftMessage, { chatId: this.props.params.id, message: this.state.message }])
  }

  onAttachmentClick = () => {
    this.setState((prevState) => ({
      showAttachmentModal: !prevState.showAttachmentModal,
      clipboardImg: null,
      file:null
    }));
  };

  onMediaSend = (media, caption, isCompress) => {
    if (media.length > 0) {
      this.setState({
        mediaData: [...media],
        showAttachmentModal: false,
        recordAudioShow: false,
        isSending: true,
      });

      this.scrollHandle();
    }
    this.sendMessageForm(caption, this.state.userId, media);
  };

  attachmentUsingFormData = async (messageId) => {
    let mediaArray = [];
    mediaArray = this.state.mediaData;
    this.setState({
      showAttachmentModal: false,
    });

    for (let i = 0; i < mediaArray.length; i++) {
      try {
        let response = await uploadAttachmentOfChat(
          this.props.token,
          messageId,
          this.getLcRoomId(),
          mediaArray[i].file,
          mediaArray[i].type,
          mediaArray[i]?.fthumbnail,
          mediaArray[i]?.audioTime
        );
        if (response.data.ER) {
          this.setState({
            errorMessage: response.data.ER.split(" ")
              .join("_")
              .substring(0, response.data.ER.length - 1),
            isSending: false,
            showErrorModal: true,
          });
          break;
        } else {
          if (mediaArray.length === i + 1) {
            this.setState({
              multipleAttchamentId: messageId,
            });
            this.onMultipleMediaSend();
          }
        }
      } catch (e) {
        console.error(e);
        break;
      }
    }
  };

  onMultipleMediaSend = () => {
    if (
      this.state.multipleAttchamentId !== null &&
      this.state.mediaData?.length > 0
    ) {
      this.getSockets()
        .then((server) => {
          let param2 = {
            event: "upload_attachment",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
            LC_chat_id: this.state.multipleAttchamentId,
            LC_reply_id: this.state.replyId ? this.state.replyId : "0",
            files: [],
          };
          server.send(JSON.stringify(param2));

          this.setState({
            selectedMsg: null,
            message: "",
            replyId: null,
            repliedData: null,
            showAttachmentModal: false,
            recordAudioShow: false,
            multipleAttchamentId: null,
            mediaData: null,
          });
        })
        .catch((err) => {
          // error here
          console.error("error on getSocktes", err);
        });
    }
  };

  sendMessageForm(message, user, media) {
    const msg = message || this.state.message;
    const userID = user || this.state.userId;

    if (media?.length > 0) {
      this.getSockets()
        .then((server) => {
          if (server.readyState) {
            let param = {
              event: "send_chat",
              LC_room_id:
                this.props.chattype === "private"
                  ? this.state.roomID
                  : this.props.match.params.id,
              LC_reply_id: this.state.replyId ? this.state.replyId : "0",
              LC_forward_id: "0",
              LC_message_type: "attachment",
              LC_message: msg,
            };

            server.send(JSON.stringify(param));
            this.setState({
              selectedMsg: null,
              message: "",
              replyId: null,
              repliedData: null,
              attchamentType: null,
            });
          }
        })
        .catch((err) => {
          // error here
          console.error("error on getSocktes", err);
        });
    } else {
      if (msg.trim().length > 0) {
        if (msg.length > 100)
          this.setState({
            isSending: true,
          });
        this.getSockets()
          .then((server) => {
            if (server.readyState) {
              let param = {
                event: "send_chat",
                LC_room_id:
                  this.props.chattype === "private"
                    ? this.state.roomID
                    : this.props.match.params.id,
                LC_reply_id: this.state.replyId ? this.state.replyId : "0",
                LC_forward_id: "0",
                LC_message_type: "text",
                LC_message: msg.trim(),
              };

              server.send(JSON.stringify(param));
              this.setState({
                selectedMsg: null,
                message: "",
                replyId: null,
                repliedData: null,
                attchamentType: null,
              });
            }
          })
          .catch((err) => {
            // error here
            console.error("error on getSocktes", err);
          });
      }
    }
  }

  handleRecordAudioModal = () => {
    this.setState((prevState) => ({
      recordAudioShow: !prevState.recordAudioShow,
      showAttachmentModal: false,
    }));
  };

  handleRecordVideo = (data) => {
    this.setState((prevState) => ({
      videoCapture: !prevState.videoCapture,
      showAttachmentModal: false,
    }));
    if (data) {
      this.onMediaSend(data);
    }
  };

  openLightBox = (media, lightboxIndex) => {
    if (media && media.length > 0) {
      this.setState({
        lightboxMedia: media,
        lightboxIndex: lightboxIndex,
        isLightboxOpen: true,
      });
    }
  };

  scrollToParentMsg = (id) => {

    this.setState({
      checkReplyMessage: true,
      onReplyLoad: true,
      replyMessageId: id
    })

    let index = this.state.messages.findIndex((me) => me.loci_messID === id);

    if (index !== -1) {
      this.setState({
        endNumberOfMsg: index + 1,
        isLoadMoreMsg: false,
        goToTheMessage: id,
        checkReplyMessage: false,
        replyMessageId: "",
        onReplyLoad: false,

      });
    } else {
      this.fetchMoreData()
    }
  };

  showParentMsg = (id) => {
    let ele = document.getElementById("mid" + id);
    if (ele) {
      this.psRef.current.scrollTop =
        ele.offsetTop - ele.clientHeight - 101 - 86;
    }
  };

  onRecordingComplete = async (data) => {
    let videoUrl = URL.createObjectURL(data);
    this.setState({
      videoBlob: videoUrl,
    });
  };

  downloadFiles = (data, attachmentID) => {
    downloadChatFiles(
      this.props.token,
      data.loci_messID,
      this.getLcRoomId(),
      attachmentID,
      "chat"
    );
  };

  //infinity scroll

  goToPrevScroll = (oldHeight = 0) => {
    const { current } = this.psRef;

    if (current) {
      current.scrollTop = current.scrollHeight - oldHeight + current.scrollTop;
    }
  };

  fetchMoreData = () => {
    // a fake async api call like which sends

    const oldHeight = this.psRef.current && this.psRef.current.scrollHeight;

    setTimeout(() => {
      const total = Number(10);
      const totalPages = Math.ceil(total / 10);
      if (this.state.endNumberOfMsg < this.state.messages.length) {
        this.setState((pre) => {
          return {
            endNumberOfMsg: pre.endNumberOfMsg + 10,
            isLoadMoreMsg: false,
            currentPage: this.state.currentPage + 1
            // hasMore: true,
          };
        });
        if (this.state.totalPages >= this.state.currentPage && this.props.query === "") {
          this.globalGetMsgList();
          this.setState({
            isSearching: false
          })
        }
      } else {
        this.setState({
          hasMore: false,
          isLoadMoreMsg: false,
        });
      }

      if (this.psRef.current && this.psRef.current.scrollTop === 0) {
        this.goToPrevScroll(oldHeight);
      }
    }, 1);
  };


  onAvatarClick() {
    this.setState({
      showUserDetails: true,
    });
  }

  deleteParticipants = (id) => {
    this.setState({
      deleteLoader: true,
      removeMemberId: id,
    });
    this.deleteMember(id);
  };

  deleteMember = (id) => {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let datas = { user_id: id };
          let params = {
            event: "update_room_info",
            action: "remove_user",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
            data: datas,
          };
          server.send(JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  sortParticipants = (data) => {
    if (!this.state.roomInfo?.admin) {
      let index;

      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.is_me) {
          index = i;
        }
      }
      if (index !== 1) {
        let obj = data[1];
        data[1] = data[index];
        data[index] = obj;
      }

      this.setState({ sortedParticipants: data });
    } else {
      this.setState({ sortedParticipants: data });
    }
  };

  addParticipant = (data) => {
    this.setState({ addLoader: true });
    let arr = [];
    data?.map((dt) => {
      arr?.push(Number(dt?.FK_userID));
    });
    this?.addMember(arr);
  };

  addMember = (arr) => {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let datas = { participant_ids: arr };
          let params = {
            event: "update_room_info",
            action: "add_participants",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
            data: datas,
          };
          server.send(JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  leaveGroup = () => {
    this.getSockets()
      .then((server) => {
        if (server.readyState) {
          let params = {
            event: "update_room_info",
            action: "leave_group",
            LC_room_id:
              this.props.chattype === "private"
                ? this.state.roomID
                : this.props.match.params.id,
          };
          server.send(JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.error("error on getSocktes", err);
      });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      this.sendMessageForm()
    };
  }

  showSystemMsg(currentMsg, index) {
    let lastMsgIndex = this.state.messages.length - 1;
    if (index === lastMsgIndex && !this.state.roomInfo.admin) {
      return "";
    } else return <div className="notificationMsg">{currentMsg?.LCM_text}</div>;
  }


  photoFileTypes = {
    "image/jpeg": true,
    "image/png": true,
    "image/bmp": true,
    "image/gif": true,
    "image/webp": true,
    "image/tiff": true,
    "image/svg+xml": true,
    "image/vnd.adobe.photoshop": true,
  };

  videoFileTypes = {
    "video/mp4": true,
    "video/x-msvideo": true,
    "video/quicktime": true,
    "video/x-flv": true,
    "video/x-matroska": true,
    "video/x-ms-wmv": true,
    "video/mpeg": true,
    "video/3gpp": true,
    "video/webm": true,
    "video/x-m4v": true,
    "application/vnd.rn-realmedia": true,
    "video/x-ms-asf": true,
    "video/dvd": true,
    "video/mp2t": true,
    "video/x-f4v": true,
    "video/x-dv": true,
    "video/divx": true,
    "video/x-xvid": true,
  };

  allowedFileTypes = {
    "image/jpeg": true,
    "image/png": true,
    "image/gif": true,
    "image/bmp": true,
    "image/tiff": true,
    "image/svg+xml": true,
    "image/vnd.adobe.photoshop": true,
    "application/postscript": true,
    "video/mp4": true,
    "video/x-msvideo": true,
    "video/quicktime": true,
    "video/x-flv": true,
    "video/x-matroska": true,
    "video/x-ms-wmv": true,
    "video/mpeg": true,
    "video/3gpp": true,
    "video/webm": true,
    "video/x-ms-asf": true,
    "video/dvd": true,
    "video/mp2t": true,
    "video/x-f4v": true,
    "video/x-dv": true,
    "video/divx": true,
    "video/x-xvid": true, 
};
  
allowedDocTypes = {
  "application/msword": true,
  "application/pdf": true,
  "application/vnd.ms-excel": true,
  "application/vnd.ms-powerpoint": true,
  "text/plain": true,
  "application/rtf": true,
  "text/csv": true,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": true,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': true,
  'application/x-gzip': true,
  "application/xml": true,
  "application/vnd.oasis.opendocument.text": true,
  "application/vnd.oasis.opendocument.spreadsheet": true,
  "application/vnd.oasis.opendocument.presentation": true,
  "application/epub+zip": true,
  "application/x-mobipocket-ebook": true,
  "application/x-tex": true,
  "text/markdown": true,
  "application/vnd.apple.keynote": true,
  "application/vnd.apple.pages": true,
  "application/vnd.apple.numbers": true,
  "application/json": true,
  "application/zip": true,
  "application/vnd.rar": true,
  "application/x-7z-compressed": true,
  "application/x-tar": true,
  "application/gzip": true,
  "application/x-bzip2": true,
  "application/x-iso9660-image": true,
  "application/x-apple-diskimage": true,
  "application/vnd.ms-cab-compressed": true,
  "application/x-lzip": true,
  "application/x-xz": true,
  "application/x-compress": true,
  "application/x-lzma": true,
  "audio/mp4": true,
  "video/x-m4v": true,
  "audio/x-aiff": true,
  "audio/midi": true,
  "audio/x-pn-realaudio": true,
  "application/vnd.rn-realmedia": true,
  "audio/opus": true,
  "audio/mpeg":true
};
  

  handleDragOver = (e) => {
    e.preventDefault();

    if(this.state.isBrowserSafari){
      this.setState({ showCommonUploader: true });
    } else {
      let fileArr = e.dataTransfer.items;
  
      let fileFlag = false;;
      let docFlag = false;
  
      for (let index = 0; index < fileArr.length; index++) {
        if( this.allowedFileTypes[fileArr[index].type] ){
          fileFlag=true;
        } else if(this.allowedDocTypes[fileArr[index].type]){
          docFlag=true;
        }
      }
  
      if(fileFlag || docFlag){
        this.setState({ showCommonUploader: true });
      } else {
        this.setState({
          errorMsg: "Please_upload_valid_document",
          showErrorModal: true,
        })
      }
    }
  };


  generateThumnailForVideo = async (data, item) => {
    try {
      const response = await fetch(data);
      const buffer = await response.arrayBuffer();
      const file = await new File([buffer], item.name, { type: "image/jpeg" });
      console.log('fn called');
      console.log('thumbnail', file);
      if(file){
        return file
      }

    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  };


  async generateVideoThumbnailAtUpload(fil) {
    try{
      let response = await generateVideoThumbnails(fil,2) 
      const file =  await this.generateThumnailForVideo(response[1], fil) 
      return file;
    } catch(error){
      console.error('Error generating video thumbnail:', error);  
    }
  }

  notAllowedFileTypes = {
    "x-php":true,
    "x-ms-dos-executable":true,
    "tiff":true
};

  handleUpdateCompression = (val) => {
    this.setState({ handleCompression: val});
  }

  handleChange = (file, uploadKey, compressionKey) => {
    let maxFileSize = 67108864;

    const filesArray = Object.values(file);

    if(compressionKey === "compressed"){
      this.handleUpdateCompression(true)
    } else if(compressionKey === "uncompressed"){
      this.handleUpdateCompression(false)
    }

    let flag = true;

    let fileFlag = false;
    let docFlag = false;

    for (let index = 0; index < file.length; index++) {
      if( this.allowedFileTypes[file[index].type] ){
        fileFlag=true;
      } else if(this.allowedDocTypes[file[index].type]){
        docFlag=true;
      }
    }

    if(!fileFlag && !docFlag){
      this.setState({
        errorMsg: "Please_upload_valid_document",
        showErrorModal: true,
      })
      this.setState({showCommonUploader:false})
      return;
    }

    Promise.all(filesArray.map(async fil => {
      const fileType = fil.type;

      if(!this.allowedFileTypes[fileType] && uploadKey==="media"){
        this.setState({
          errorMsg: "Please_upload_valid_document",
          showAttachmentModal:false,
          showErrorModal: true,
        });

        flag = false;
      } else if(!this.allowedDocTypes[fileType] && this.notAllowedFileTypes[fileType] && uploadKey==="document"){
        this.setState({
          errorMsg: "Please_upload_valid_document",
          showAttachmentModal:false,
          showErrorModal: true,
        }); 

        flag = false;
      } else if(fil.size > maxFileSize) {
        this.setState({
          errorMsg: "Please_upload_less_then_64MB_document",
          showAttachmentModal:false,
          showErrorModal: true,
        });

        flag = false;
      }

      const preview = URL.createObjectURL(fil);
      
      let fileTypeCode = "p";
  
      if (fileFlag && docFlag) {
        fileTypeCode = "d";
      } else {
        if (this.photoFileTypes[fileType]) {
          fileTypeCode = "p";
        } else if (this.videoFileTypes[fileType]) {
          fileTypeCode = "v";

          try {
            const thumbnail = await this.generateVideoThumbnailAtUpload(fil);

            return {
              preview: preview,
              fthumbnail: thumbnail,
              file: fil,
              type: fileTypeCode
            };
          } catch (error) {
            console.error('Error generating video thumbnail:', error);
            return {
              preview: preview,
              fthumbnail: null,
              file: fil,
              type: fileTypeCode
            };
          }
        } else {
          fileTypeCode = "d";
        }
      }
      
      return {
        preview: preview,
        file: fil,
        type: fileTypeCode
      };
    })).then(fileObjects => {
      this.setState({ file: fileObjects });

      if (fileFlag || docFlag) {
        this.setState({ showCommonUploader: false });
      } 
      if(this.state.showCommonUploader){
        this.setState({showCommonUploader:false})
      }

      if(flag) {
        this.setState({ showAttachmentModal: true });
      }
    });
  };
  
  
  handleModalVisibility =(v, key)=>{
    if(!this.fileUploaderRender.current) {
      return;
    }

    clearTimeout(this.currentFocusEl);

    this.currentFocusEl = setTimeout(()=>{
      if(v===false && key=="allmedia" && this.state.showCommonUploader){
        this.setState({showCommonUploader:false});
      }
    }, 200);
   
  }

  handleSearchToggle = () => {
    this.setState(prevState => ({
      toggleSearch: !prevState.toggleSearch
    }), () => {
      this.props.search("");
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <>
            <MessageProvider value={this.state.messageContext}>
              <div className="chat-window ">
                {this.state.videoBlob && (
                  <video source={this.state.videoBlob} controls />
                )}
                {this.state.isSending && (
                  <div className="sending-overlay">
                    <div class="loader">
                      <Spinner
                        animation="border"
                        role="status"
                        color="var(--primary-color)"
                      ></Spinner>
                    </div>
                  </div>
                )}
                <div 
                  className="chat-window-narrow" 
                  ref={this.chatWindowRef} 
                  style={{ position: 'relative' }} 
                  onDragOver={this.handleDragOver}
                  onDrop={this.handleDrop} 
                >
                  <>
                    <div className="group-title">
                      <div className="avatar-title">
                        <LocciAvatar
                          title={
                            this.props.chattype === "private"
                              ? this.state.userData?.title
                              : this.state.roomInfo.CAR_room
                          }
                          picture={
                            this.props.chattype === "private"
                              ? this.state.userData?.userPicture
                              : this.state.roomInfo.CAR_avatar
                          }
                          size={"60px"}
                          onAvatarClick={() =>
                            this.props.history.push(
                              this.props.chattype === "private"
                                ? `/chat-info/${this.props.match.params.id}`
                                : `/group-chat-info/${this.props.match.params.id}`
                            )
                          }
                          className="cursor"
                        />
                        <h2 className="receiver">
                          {this.props.chattype === "private"
                            ? this.state.userData?.title
                            : this.state.roomInfo.CAR_room}
                        </h2>

                        <div className="actions">

                        <IconButton
                          icon={this.state.toggleSearch ? "close" : "search"}
                          className="black"
                          clear="true"
                          handleClick={this.handleSearchToggle}
                        />

                          <Dropdown>
                            <Dropdown.Toggle
                              id="more-grp"
                              as="div"
                              className="btn btn-clear"
                            >
                              <div>
                                <IconButton
                                  icon="more_vert"
                                  clear={true}
                                  className="float-right"
                                />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <IconLink
                                text={
                                  this.props.chattype === "private"
                                    ? t("chat_info")
                                    : t("enter_group_screen_group_info")
                                }
                                icon="info"
                                clear="true"
                                link={
                                  this.props.chattype === "private"
                                    ? `/chat-info/${this.props.match.params.id}`
                                    : `/group-chat-info/${this.props.match.params.id}`
                                }
                                query={{
                                  name: this.state.userData?.title,
                                  img: this.state.userData?.userPicture,
                                }}
                              />

                              {this.props.chattype === "private" && <IconLink
                                icon="delete"
                                clear={true}
                                className="float-right"
                                text={t(
                                  this.props.chattype === "private"
                                    ? "delete_prvate"
                                    : ""
                                )}
                                handleClick={() =>
                                  this.setState({ showDeleteModal: true })
                                }
                              />}

                              {this.props.chattype === "group" && (
                                <IconLink
                                  text={t("participants")}
                                  icon="group"
                                  clear="true"
                                  handleClick={() =>
                                    this.setState({ allParticipants: true })
                                  }
                                //    link="/settings"
                                />
                              )}
                              {this.props.chattype === "group" &&
                                this.state.roomInfo?.admin && (
                                  <IconLink
                                    text={t("add-participants")}
                                    icon="person_add_alt_1"
                                    clear="true"
                                    handleClick={() =>
                                      this.setState({ addParticipants: true })
                                    }
                                  //    link="/settings"
                                  />
                                )}
                              {this.props.chattype === "group" &&
                                !this.state.roomInfo?.admin && (
                                  <IconLink
                                    text={t("group_portal_leave_group")}
                                    icon="exit_to_app"
                                    clear="true"
                                    handleClick={() =>
                                      this.setState({ exitGroup: true })
                                    }
                                  />
                                )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                      </div>
                      {this.state.toggleSearch && (
                        <ActionBar
                          sticky={false} 
                          style={{maxWidth: 600, margin:'0 auto'}} 
                          containerStyle={{border:0}}
                        />
                      )}
                    </div>
                  </>

                  <div className="new-message">
                    {this.state.repliedData && (
                      <div className="reply-msg-cont">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="sendername">

                            {this.state.repliedData?.LCM_from ===
                              this.state.currentUserDetails?.userId
                              ? t("you_title")
                              : this.state.selectedMsgName || ""}
                            <div
                              style={{ wordBreak: "break-word" }}
                              className="msg"
                            >
                              {this.state.repliedData.LCM_text}
                            </div>
                            {this.state.repliedData.LCM_attachment[0]
                              ?.atype && (
                                <div className="thumbnail pr-10">
                                  {getAttchamentIcon(this.state.repliedData)}
                                </div>
                              )}
                          </div>

                          {this.state.repliedData.LCM_attachment[0]?.atype ===
                            "v" ||
                            (this.state.repliedData.LCM_attachment[0]?.atype ===
                              "p" && (
                                <div className="thumbnail pr-10">
                                  <img
                                    src={
                                      this.state.repliedData.LCM_attachment[0]
                                        ?.atype === "p"
                                        ? this.state.repliedData.LCM_attachment[0]
                                          ?.url
                                        : this.state.repliedData.LCM_attachment[0]
                                          ?.fthumbnail
                                    }
                                    width={40}
                                    height={40}
                                    alt={""}
                                  />

                                  <span className="reverseicon">
                                    <i className="material-icons"></i>
                                  </span>
                                </div>
                              ))}
                        </div>
                        <div className="crossCont">
                          <i
                            className="material-icons green"
                            onClick={() =>
                              this.setState({
                                selectedMsg: "",
                                replyId: null,
                                parentMsgThumb: null,
                                attchamentType: null,
                                repliedData: null,
                              })
                            }
                          >
                            close
                          </i>
                        </div>
                      </div>
                    )}
                    {!this.state.isUserBlocked && (
                      <Form className="new-message-form">
                        <IconButton
                          icon="add"
                          clear={true}
                          className={"emoji-size"}
                          handleClick={this.onAttachmentClick}

                        />

                        <IconButton
                          icon="emoji_emotions"
                          clear={true}
                          className={"emoji-size pr-2 pl-2"}
                          // handleClick={this.handleRecordAudioModal}
                          handleClick={this.onEmojiClick}
                        />
                        <Form.Group size="lg" className="form-group">
                          <Form.Control
                            autoFocus
                            as="textarea"
                            type="text"
                            required
                            placeholder={t("placeholder_input_type_a_message")}
                            value={this.state.message}
                            data-emoji-input="unicode"
                            emojiable="true"
                            autoComplete="off"
                            className="msg-input"
                            id="chatinput"
                            onChange={(e) => {
                              // console.log("check", e.target.value);
                              this.firstTimeRender.current = false
                              this.setState({ message: e.target.value });
                            }}
                            onKeyUp={this.handleEnterKeyPress}
                            ref={this.textareaRef}
                          />
                        </Form.Group>
                        <Modal
                          centered
                          show={this.state.isStickerVisible}
                          onHide={this.onEmojiClick}
                        >
                          <Picker
                            onEmojiClick={this.onEmojiSelect}
                            preload={true}
                            skinTonesDisabled={true}
                            defaultSkinTone="SKIN_TONE_MEDIUM_DARK"
                            native={true}
                            height={"400px"}
                            width={"100%"}
                          />
                        </Modal>

                        {this.state.recordAudioShow && (
                          <AudioRecording
                            handleClose={this.handleRecordAudioModal}
                            onSendClick={this.onMediaSend}
                          />
                        )}

                        <IconButton
                          icon={"send"}
                          clear={true}
                          className={"emoji-size"}
                          handleClick={
                            () => this.sendMessageForm()
                          }
                        />
                      </Form>
                    )}
                  </div>
                  {this.state.onReplyLoad && <div style={{ position: 'absolute', height: '100vh', left: 0, width: '100%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backdropFilter: 'blur(2px)', zIndex: '1' }}>
                    <Spinner
                      animation="border"
                      role="status"
                      color="var(--primary-color)"
                    ></Spinner>
                  </div>}
                  <div
                    id="scrollableDiv"
                    style={{
                      // height: this.state.chatInnerHeight,
                      flex:1,
                      overflow: `${this.state.os === "Windows" &&
                        this.state.browser === "Chrome"
                        ? "auto"
                        : "auto"
                        }`,
                      display: "flex",
                      flexDirection: "column-reverse",
                      position: 'relative'
                    }}
                    ref={this.psRef}
                  >

                    <InfiniteScroll
                      dataLength={this.state.endNumberOfMsg + 10}
                      next={this.fetchMoreData}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        // overflow: `${this.state.os==="Windows" && this.state.browser==="Chrome"?'hidden':'auto'}`,
                      }}
                      //To put endMessage and loader to the top.
                      inverse={true}
                      className="cutomScroll"
                      hasMore={this.state.hasMore}
                      loader={
                        <h4 style={{ textAlign: "center" }}>
                          {t("loading")}...
                        </h4>
                      }
                      scrollableTarget="scrollableDiv"
                      scrollThreshold={0.7}
                    >

                      <div className="messages-wrapper" id="messages-wrapper">
                        {this.state.messages &&
                          this.state.messages
                            .slice(
                              this.state.startNumberOfMsg,
                              this.state.endNumberOfMsg
                            )
                            .map((x, i) => (
                              <div className="mainMsgBox" key={`msgwrap${x.loci_messID}${i}`}>
                                {i == this.state.messages.length - 1 && (
                                  <div className="msgDate">
                                    {moment(x?.LCM_date).format("DD/MM/YYYY")}
                                  </div>
                                )}
                                {i != this.state.messages.length - 1 &&
                                  this.state.messages &&
                                  moment(x?.LCM_date).format("DD/MM/YYYY") !==
                                  moment(
                                    this.state?.messages[i + 1]?.LCM_date
                                  ).format("DD/MM/YYYY") && (
                                    <div className="msgDate">
                                      {moment(x?.LCM_date).format("DD/MM/YYYY")}
                                    </div>
                                  )}

                                {x?.LC_message_type === "system" ? (
                                  this.showSystemMsg(x, i)
                                ) : (
                                  <div className="bubbleBoxe">
                                    {this.props.chattype === "group" &&
                                      this.state.userId != x?.LCM_from && (
                                        <LocciAvatar
                                          title={"aa"}
                                          picture={x.USE_pic}
                                          size={"40px"}
                                          onAvatarClick={() =>
                                            this.setState({
                                              userIdForModal: x?.LCM_from,
                                              showUserDetails: true,
                                            })
                                          }
                                          className="cursor"
                                        />
                                      )}
                                    <Bubble
                                      data={x}
                                      key={i} 
                                      owned={
                                        this.props.chattype === "private"
                                          ? x.LCM_from !=
                                          this.props.match.params.id
                                          : x.LCM_from == this.state.userId
                                      }
                                      GroupChat={
                                        this.props.chattype === "group"
                                      }
                                      colors={this.state.tempData[x?.LCM_from]}
                                      index={i}
                                      userId={this.state.userId}
                                      userData={this.state.userData}
                                      deleteMessage={this.deleteMessage}
                                      setSelectedMsg={this.setSelectedMsg}
                                      forwardClick={this.handleUserListOpen}
                                      onLightBoxClick={this.openLightBox}
                                      parentMsgClick={this.scrollToParentMsg}
                                      onSaveFiles={this.downloadFiles}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>

              {this.state.showUserList && (
                <ForwardGroupChatModal
                  {...this.props}
                  handleClose={this.handleUserListClose}
                  handleForword={this.forwordMessage}
                />
              )}

              {this.state.showAttachmentModal && (
                <AttachmentModal
                  {...this.props}
                  handleClose={this.onAttachmentClick}
                  handleMediaSend={this.onMediaSend}
                  onRecordAudioClick={this.handleRecordAudioModal}
                  handleVideoRecording={this.handleRecordVideo}
                  clipboardData={this.state.clipboardImg ? this.state.clipboardImg : this.state.file}
                  handleCompression={this.state.handleCompression}
                  onUpdateCompression={this.handleUpdateCompression}
                />
              )}

              {this.state.isLightboxOpen && (
                <Lightbox
                  onClose={() => this.setState({ isLightboxOpen: false })}
                  media={this.state.lightboxMedia}
                  index={this.state.lightboxIndex}
                  setIndex={(i) => this.setState({ lightboxIndex: i })}
                />
              )}
            </MessageProvider>

            <Modal
              centered
              show={this.state.showDeleteModal}
              onHide={() => this.setState({ showDeleteModal: false })}
            >
              <Modal.Body>
                <h2>
                  {t(
                    this.props.chattype === "private"
                      ? "delete_msg_history_title_private"
                      : "delete_msg_history_title_group"
                  )}
                </h2>
                <p>{t("Are_you_sure_want_to_delete_chat")}</p>

                <div className="report-actions">
                  <Button
                    clear="true"
                    text={t("cancel_alert")}
                    handleClick={() =>
                      this.setState({ showDeleteModal: false })
                    }
                  />
                  <Button text={t("ok")} handleClick={this.deleteAllMessage} />
                </div>
              </Modal.Body>
            </Modal>

            {this.state.videoCapture && (
              <VideoCapture
                onRecordingComplete={(blobData) =>
                  this.onRecordingComplete(blobData)
                }
              />
            )}
          </>
        )}

        {this.state.showErrorModal && (
          <Modal
            show={this.state.showErrorModal}
            onHide={() => this.setState({ showErrorModal: false })}
            className="custom-modal "
          >
            <IconButton
              clear="true"
              className="closeIcon"
              icon="close"
              handleClick={() => this.setState({ showErrorModal: false })}
            />
            <div>{t(this.state.errorMessage)}</div>
          </Modal>
        )}

        {this.state.allParticipants && (
          <AllPaticipantsModal
            {...this.props}
            show={this.state.allParticipants}
            name={this.state?.roomInfo?.CAR_room}
            participantData={
              this.state?.sortedParticipants
                ? this.state?.sortedParticipants
                : []
            }
            closeModal={() =>
              this.setState({
                allParticipants: false,
              })
            }
            deleteParticipants={(id) => this.deleteParticipants(id)}
            loader={this.state.deleteLoader}
            isAdmin={this?.state.roomInfo?.admin || false}
          />
        )}

        {this.state.showToast && <ToastModal msg={this.state.toastMessage} />}
        {this.state.groupLeft && <ToastModal msg={t("group_chat_left")} />}
        {this.state.addParticipants && (
          <AddParticipantsModal
            show={this.state.addParticipants}
            name={this.state?.roomInfo?.CAR_room}
            userList={this.state.allUsers}
            participantData={this.state.participantDatas}
            closeModal={() =>
              this.setState({
                addParticipants: false,
              })
            }
            addParticipants={(data) => this.addParticipant(data)}
            loader={this.state.addLoader}
            isAdmin={this?.state.roomInfo?.admin || false}
            token={this.props.token}
          />
        )}

        {this.state.exitGroup && (
          <GlobalConfirmDialogue
            show={this.state.exitGroup}
            title={"group_portal_leave_modal_leave_button_text"}
            content={"group_portal_leave_group_text"}
            name={this.state?.roomInfo?.CAR_room}
            closeModal={() =>
              this.setState({
                exitGroup: false,
              })
            }
            onSubmit={() => {
              this.leaveGroup();
              this.setState({
                exitGroup: false,
              });
            }}
          />
        )}

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userIdForModal}
            {...this.props}
            // sourcePage="chat"
            handleClose={() =>
              this.setState({
                showUserDetails: false,
              })
            }
          />
        )}
        <img className="preview" id="preview" />
 
        {/* common modal for drag and drop media in chat */}
        <Modal
          show={this.state.showCommonUploader}
          onHide={() => this.setState({ showCommonUploader: false })}
          className="fileUploaderModal"
          onEntered={()=>{
            this.fileUploaderRender.current = true
          }}
          onExit={()=>{
            this.fileUploaderRender.current = false
          }}
        >
          <Modal.Body>
            <div className="file-uploader-container">
              <FileUploader
                multiple={true}
                handleChange={(file) => this.handleChange(file, "document", "compressed")}
                name="file"
                types={allFileTypes}
                onDraggingStateChange={v=> this.handleModalVisibility(v, "allmedia")}
                classes="drop_area"
                dropMessageStyle={{ color: 'transparent' }}
                children={<CustomMessage t={t} heading={t("drop_media_here")} />}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
            show={this.state.showErrorModal}
            onHide={() => this.setState({ showErrorModal: false })}
            className="custom-modal error-modal"
          >
            <IconButton
              clear="true"
              className="closeIcon"
              icon="close"
              handleClick={() => this.setState({ showErrorModal: false })}
            />
            <div>{t(this.state.errorMsg)}</div>
          </Modal>
      </>
    );
  }
}

const withSocketContext = (Component) => {
  const Wrapper = (props) => {
    const context = useSocketContext();

    return <Component socketContext={context} {...props} />;
  };

  return Wrapper;
};

const mapDispatchToProps = {
  setForwardMessage: GlobalStore.setForwardMessage,
  setMyDraftMessage: GlobalStore.setMyDraftMessage,
  search: GlobalStore.search,
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(
    withRouter(
      withScrollHookHOC(
        withSocketContext(ChatComponent)
      )
    )
  )
);
