const color = "var(--secondary-color)";
const icons = {
  like: {
    filled: {
      path: "M10.5003 16C8.16699 14.6666 3.40032 11.2 3.00032 7.99995C2.50032 3.99995 6.00032 1.99995 8.50032 2.99995C10.0297 3.61171 10.4869 4.16501 10.5428 4.61511C10.7156 3.84848 11.4533 3 13.5 3C16.5 3 18.5 4.5 18 8C17.6 10.8 12.8335 14.5 10.5003 16Z",
      fill: true,
      stroke: false,
    },
    outline: {
      path: "M14.0859 2.0625C13.4136 2.06328 12.7486 2.2018 12.1318 2.46951C11.5151 2.73722 10.9597 3.12845 10.5 3.61906C9.8263 2.90004 8.95201 2.40016 7.99062 2.18429C7.02924 1.96843 6.02516 2.04657 5.10874 2.40855C4.19232 2.77054 3.40589 3.39966 2.85153 4.21424C2.29717 5.02882 2.00049 5.99124 2 6.97656C2 11.8694 9.89172 16.6719 10.2344 16.8605C10.317 16.9101 10.4116 16.9363 10.508 16.9363C10.6044 16.9363 10.6989 16.9101 10.7816 16.8605C11.1083 16.6719 19 11.8694 19 6.97656C18.9986 5.6737 18.4804 4.42461 17.5592 3.50335C16.6379 2.58209 15.3888 2.06391 14.0859 2.0625ZM10.5 15.7794C9.12672 14.9028 3.0625 10.8069 3.0625 6.97656C3.06318 6.15842 3.32437 5.36175 3.8082 4.70201C4.29204 4.04228 4.97339 3.55374 5.7535 3.30722C6.53362 3.0607 7.37197 3.06899 8.14705 3.3309C8.92214 3.59281 9.59369 4.09472 10.0644 4.76391C10.1134 4.83351 10.1785 4.89031 10.254 4.92952C10.3296 4.96873 10.4135 4.98919 10.4987 4.98919C10.5838 4.98919 10.6677 4.96873 10.7433 4.92952C10.8189 4.89031 10.8839 4.83351 10.933 4.76391C11.4033 4.09357 12.075 3.59057 12.8506 3.32793C13.6262 3.06528 14.4653 3.05666 15.2461 3.30331C16.0269 3.54997 16.7088 4.03906 17.1928 4.69959C17.6768 5.36012 17.9376 6.1577 17.9375 6.97656C17.9375 10.8042 11.8733 14.9002 10.5 15.7794Z",
      fill: true,
      stroke: false,
    },
  },
  comment: {
    filled: {
      path: "M4.31217 16.0001C4.59529 16.0001 4.80581 15.8553 5.14701 15.5366L7.76773 13.1463H12.6751C14.8385 13.1463 16 11.9585 16 9.83628V4.28805C16 2.16582 14.8385 0.970703 12.6751 0.970703H3.32487C1.16153 0.970703 0 2.15857 0 4.28805V9.83628C0 11.9657 1.16153 13.1463 3.32487 13.1463H3.66607V15.2541C3.66607 15.7031 3.89837 16.0001 4.31217 16.0001Z",
      fill: true,
      stroke: false,
    },
    outline: {
      path: "M6.96461 17.0678C7.32033 17.0678 7.56716 16.8785 8.00999 16.4852L10.5145 14.2494H15.1751C17.3385 14.2494 18.5 13.0478 18.5 10.914V5.33546C18.5 3.20164 17.3385 2 15.1751 2H5.82487C3.66153 2 2.5 3.19436 2.5 5.33546V10.914C2.5 13.0551 3.66153 14.2494 5.82487 14.2494H6.17333V16.1429C6.17333 16.7037 6.45645 17.0678 6.96461 17.0678ZM7.26225 15.7351V13.6231C7.26225 13.2298 7.10981 13.0769 6.71779 13.0769H5.82487C4.35844 13.0769 3.66879 12.3268 3.66879 10.9067V5.33546C3.66879 3.91534 4.35844 3.17251 5.82487 3.17251H15.1751C16.6343 3.17251 17.3312 3.91534 17.3312 5.33546V10.9067C17.3312 12.3268 16.6343 13.0769 15.1751 13.0769H10.471C10.0644 13.0769 9.86116 13.1352 9.58531 13.4192L7.26225 15.7351Z",
      fill: true,
      stroke: false,
    },
  },
  favorite: {
    filled: {
      path: "M9.11878 1.64276C9.49592 0.939705 10.5041 0.939706 10.8812 1.64276L12.8575 5.32686C13.0018 5.59585 13.261 5.7842 13.5614 5.83831L17.6759 6.57938C18.4611 6.7208 18.7726 7.67963 18.2205 8.25556L15.3274 11.2735C15.1162 11.4939 15.0172 11.7986 15.0585 12.1011L15.6252 16.2432C15.7333 17.0336 14.9177 17.6262 14.1993 17.2791L10.4351 15.4602C10.1602 15.3274 9.83978 15.3274 9.56493 15.4602L5.80066 17.2791C5.0823 17.6262 4.26668 17.0336 4.37482 16.2432L4.94146 12.1011C4.98283 11.7986 4.88381 11.4939 4.67257 11.2735L1.77948 8.25556C1.22738 7.67963 1.53892 6.7208 2.32411 6.57938L6.43858 5.83831C6.739 5.7842 6.99825 5.59585 7.14254 5.32686L9.11878 1.64276Z",
      fill: true,
      stroke: false,
    },
    outline: {
      path: "M10.5 2.20516L12.4785 6.54418L12.5994 6.8094L12.8898 6.83484L17.8041 7.2653L14.1181 10.3235L13.8789 10.5219L13.951 10.8243L15.0387 15.382L10.7446 12.9739L10.5 12.8367L10.2554 12.9739L5.96133 15.382L7.04897 10.8243L7.12113 10.5219L6.88189 10.3235L3.19595 7.2653L8.1102 6.83484L8.40057 6.8094L8.52151 6.54418L10.5 2.20516Z",
      fill: false,
      stroke: true,
    },
  },
  share: {
    filled: {
      path: "M2.53333 16.4995C2.49267 16.4995 2.4516 16.4948 2.41093 16.4854C2.17027 16.4275 2 16.2108 2 15.9611C2 11.0682 2.61307 6.99891 10 6.81382V3.03838C10 2.82434 10.1255 2.63091 10.3193 2.54516C10.5125 2.45995 10.7391 2.49683 10.8953 2.64302L17.8287 9.10437C17.938 9.20573 18 9.34936 18 9.49972C18 9.65008 17.938 9.79372 17.8287 9.89521L10.8953 16.3566C10.7396 16.5027 10.5131 16.5406 10.3193 16.4544C10.1255 16.3685 10 16.1751 10 15.9611V12.1983C4.9328 12.3208 3.99787 14.208 3.0104 16.2019C2.9188 16.3875 2.73173 16.4995 2.53333 16.4995ZM10.5333 11.1151C10.8281 11.1151 11.0667 11.3559 11.0667 11.6535V14.7311L16.6803 9.49972L11.0667 4.26832V7.34594C11.0667 7.64357 10.8281 7.88439 10.5333 7.88439C4.6416 7.88439 3.37293 10.1849 3.12347 13.8594C4.22813 12.3613 6.11093 11.1151 10.5333 11.1151Z",
      fill: true,
      stroke: false,
    },
    outline: {
      path: "M2.53333 16.4995C2.49267 16.4995 2.4516 16.4948 2.41093 16.4854C2.17027 16.4275 2 16.2108 2 15.9611C2 11.0682 2.61307 6.99891 10 6.81382V3.03838C10 2.82434 10.1255 2.63091 10.3193 2.54516C10.5125 2.45995 10.7391 2.49683 10.8953 2.64302L17.8287 9.10437C17.938 9.20573 18 9.34936 18 9.49972C18 9.65008 17.938 9.79372 17.8287 9.89521L10.8953 16.3566C10.7396 16.5027 10.5131 16.5406 10.3193 16.4544C10.1255 16.3685 10 16.1751 10 15.9611V12.1983C4.9328 12.3208 3.99787 14.208 3.0104 16.2019C2.9188 16.3875 2.73173 16.4995 2.53333 16.4995ZM10.5333 11.1151C10.8281 11.1151 11.0667 11.3559 11.0667 11.6535V14.7311L16.6803 9.49972L11.0667 4.26832V7.34594C11.0667 7.64357 10.8281 7.88439 10.5333 7.88439C4.6416 7.88439 3.37293 10.1849 3.12347 13.8594C4.22813 12.3613 6.11093 11.1151 10.5333 11.1151Z",
      fill: true,
      stroke: false,
    },
  },
};

export const ActionIcon = (props: {
  type: "like" | "comment" | "favorite" | "share";
  filled?: boolean;
  color?: string;
}) => {
  const icon = icons[props.type][props.filled ? "filled" : "outline"];

  return (
    <svg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d={icon.path}
        fill={icon.fill ? props?.color ?? color : ""}
        stroke={icon.stroke ? props?.color ?? color : ""}
      />
    </svg>
  );
};
