import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { saveGroup } from "api/groups";
import { Card } from "components/shared/card";
import { GroupSettingsData } from "lib/types/groupTypes";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormGroupList } from "./style";

type SettingsTabProps = {
  token: string;
  groupId: string | number;
  data: GroupSettingsData;
  onSettingsChange?: () => void;
};

export const SettingsTab = ({ token, groupId, data, onSettingsChange }: SettingsTabProps) => {
  const { t } = useTranslation();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [groupData, setGroupData] = useState({
    avatar: [],
    docFiles: [],
    rulesFiles: [],
    about: "",
    groupName: "",
    phoneNumber: "",
    website: "",
    admins: [],
    isInvite: true,
    isMemberlist: true,
    isAllowPost: true,
    isConfirm: false,
    LGP_shareable: true,
    LGP_shareable_other_group: true,
    LGP_posttopublic: false,
    promocode: "",
    saving: false,
    isLoading: false,
    showErrorModal: false,
    message: "",
    regTown: null,
    noDataFoundMsg: "",
    isPrivate: false,
  });

  const [updateValue, setUpdateValue] = useState<{ name: string; value: any } | null>(null);

  useEffect(() => {
    const rules: any[] = [];
    for (const item of data.LGP_grouprules) {
      const file = { name: item.name, type: "g", id: item.id };
      rules.push(file);
    }

    const media: any[] = [];
    for (const item of data?.LGP_media ?? []) {
      const file = { name: item.name, type: "m", id: item.id };
      media.push(file);
    }

    setGroupData((groupData: any) => ({
      ...groupData,
      editedGroup: groupId,
      about: data.LGP_about,
      groupName: data.LGP_name,
      phoneNumber: data.LGP_phone,
      website: data.LGP_web,
      admins: data.LGP_admin.map((x) => x.FK_userID.toString()),
      promocode: data.LGP_promo,
      regTown: data.FK_adr_cityID,
      myState: data.FK_stateID,
      emailAddress: data.LGP_contact,
      isInvite: data.LGP_invite === "y",
      isMemberlist: data.LGP_viewmember === "y",
      isAllowPost: data.LGP_post === "y",
      isConfirm: data.LGP_confirm === "y",
      LGP_shareable: data.LGP_shareable === "y",
      LGP_shareable_other_group: data.LGP_share_to_group === "y",
      LGP_posttopublic: data.LGP_posttopublic === "y",

      isPrivate: data.LGP_public === "n",
      avatar: data.UCT_avatar ? [{ preview: data.UCT_avatar }] : [],
      docFiles: media,
      rulesFiles: rules,
    }));

    setIsSuperAdmin(data.superAdmin === "1");
    
  }, [data]);

  useEffect(() => {
    if (!updateValue) return;

    saveGroup(token, groupData)
      .then((res) => {
        if (res.data.ER) {
          setGroupData({ ...groupData, [updateValue.name]: !updateValue.value });
          alert(res.data.ER);
        } else {
          if (onSettingsChange) {
            onSettingsChange();
          }
        }
      })
      .finally(() => {
        setSavingData(false);
        setUpdateValue(null);
      });
  }, [updateValue]);

  const onSwitchChange = (event: SyntheticEvent<any, any>) => {
    if (savingData) return;
    if (!isSuperAdmin) return;

    const name = (event.target as any)?.name;
    const value = (event.target as any)?.checked;
    if (name === "isPrivate") {
      setGroupData({ ...groupData, [name]: !value });
    } else {
      setGroupData({ ...groupData, [name]: value });
    }
    setSavingData(true);
    setUpdateValue({ name, value });
  };

  return (
    <>
      <Card>
        <FormGroupList>
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={!groupData.isPrivate} />
            }
            label={t("add_edit_group_type_public")}
            labelPlacement='start'
            name='isPrivate'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={groupData.isInvite} />
            }
            label={t("add_edit_group_checkbox_member_invite_text")}
            labelPlacement='start'
            name='isInvite'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={groupData.isMemberlist} />
            }
            label={t("add_edit_group_checkbox_member_enter_memberlist_text")}
            labelPlacement='start'
            name='isMemberlist'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={groupData.isAllowPost} />
            }
            label={t("add_edit_group_checkbox_member_create_posts_text")}
            labelPlacement='start'
            name='isAllowPost'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={groupData.isConfirm} />
            }
            label={t("add_edit_group_checkbox_member_enter_confirmation_text")}
            labelPlacement='start'
            name='isConfirm'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch disabled={!isSuperAdmin || savingData} checked={groupData.LGP_shareable} />
            }
            label={t("add_edit_group_checkbox_member_can_share_grp_post")}
            labelPlacement='start'
            name='LGP_shareable'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch
                disabled={!isSuperAdmin || savingData}
                checked={groupData.LGP_shareable_other_group}
              />
            }
            label={t("add_edit_group_checkbox_member_can_share_grp_post_in_other_group")}
            labelPlacement='start'
            name='LGP_shareable_other_group'
            onChange={onSwitchChange}
          />
          <FormControlLabel
            control={
              <IOSSwitch
                disabled={!isSuperAdmin || savingData || groupData.isPrivate}
                checked={groupData.LGP_posttopublic && !groupData.isPrivate}
              />
            }
            label={t("add_edit_group_checkbox_post_to_public")}
            labelPlacement='start'
            onChange={onSwitchChange}
            name='LGP_posttopublic'
          />
        </FormGroupList>
      </Card>
    </>
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(() => ({
  width: "51px !important",
  height: "31px !important",
  padding: "0px !important",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--secondary-color)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "#A3AB90",
        opacity: 1,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "var(--secondary-color-lighten-4)",
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 27,
    height: 27,
  },
  "& .MuiSwitch-track": {
    borderRadius: 31 / 2,
    backgroundColor: "var(--secondary-color-lighten-4)",
    opacity: 1,
    transition: "background-color 500ms",
  },
}));
